let oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
let firstDate = new Date('2016-10-05')
let lastDate = new Date('2060-10-05')
let currentDate = new Date()
let fromPast = Math.round(Math.abs((firstDate.getTime() - currentDate.getTime()) / (oneDay)))

let total = (lastDate - firstDate) / oneDay
let progress = (fromPast / total) * 100

let diffDays = {
  fromPast: fromPast,
  toFuture: Math.round(Math.abs((lastDate.getTime() - currentDate.getTime()) / (oneDay))),
  progress: Math.round(progress)
}

export default diffDays
