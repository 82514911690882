import React, { Component } from 'react';
import { photos2024 } from "./photos";
import days from "./calc";
import "./App.css";
import "./balloon.css";

const today = new Date();
let isNameDay = today.getUTCDate() === 15 && today.getMonth() + 1 === 10;
let isBirthDay = today.getUTCDate() === 21 && today.getMonth() + 1 === 3;

function getRandomSixItemsFromArray(photos) {
  const items = [];
  while (items.length < 6) {
    const item = photos[Math.floor(Math.random() * photos.length)];
    if (!items.includes(item)) {
      items.push(item);
    }
  }
  return items;
}

const photo = getRandomSixItemsFromArray(photos2024);

class App extends Component {
  render() {
    return (
      <div className="container">
        <div className="grid-container">
          <div className="a">
            <img src={photo[4]} alt="" />
          </div>
          <div className="b">
            <img src={photo[1]} alt="" />
          </div>
          <div className="c">
            <img src={photo[2]} alt="" />
          </div>
          <div className="d">
            <img src={photo[3]} alt="" />
          </div>
          <div className="e">
            <img src={photo[0]} alt="" />
          </div>
          <div className="f">
            <img src={photo[5]} alt="" />
          </div>
        </div>
        <div className="slogan">
          {isNameDay || isBirthDay ? (
            <span>
              Všechno nejlepší{isNameDay ? " k svátku" : ""}, Terezko ❤
            </span>
          ) : (
            <span>Ahoj, Terezko ❤</span>
          )}

          <br />
          <small>
            Děkuji za&nbsp;krásných {days.fromPast}&nbsp;dní, na&nbsp;další
            se&nbsp;těším, B. 🍓
          </small>
        </div>
        {isNameDay || isBirthDay ? (
          <div className="balloon-cover">
            <div className="balloon"></div>
            <div className="balloon"></div>
            <div className="balloon"></div>
            <div className="balloon"></div>
            <div className="balloon"></div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default App;
